<template>
    <div>
        <el-form ref="queryForm" class="query-box"  v-model="queryParams" :inline="true">
            <el-form-item label="打款时间" prop="name" label-width="120px">
                <el-date-picker size="small"
                    v-model="dateVal"
                    type="daterange"
                    @change="dateChange"
                    range-separator="-"
                    value-format="yyyy-MM-dd"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                    </el-date-picker>
            </el-form-item>
            <el-form-item label="提现订单" prop="orderNo" label-width="120px">
                <el-input v-model="queryParams.extractionOrderNo"
                          clearable
                          placeholder="请输入打款订单"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
            <el-form-item label="打款状态" prop="name" label-width="120px">
                <el-select v-model="queryParams.transferStatus" placeholder="请选择打款状态" size="small" style="width: 240px">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="初始化" value="0"></el-option>
                    <el-option label="代付成功" value="1"></el-option>
                    <el-option label="代付失败" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="打款流水" prop="name" label-width="120px">
                <el-input v-model="queryParams.payOrderNo"
                          clearable
                          placeholder="请输入打款流水"
                          style="width: 240px"
                          size="small" @keyup.enter.native="handleQuery"/>
            </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'TRANSFER_ORDER_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery" v-permission="'TRANSFER_ORDER_QUERY'">
                    重置
                </el-button>
                <el-button icon="el-icon-download" size="mini"
                           @click="handleExport" v-permission="'TRANSFER_ORDER_EXPORT'">
                    导出
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {WithdrawApi} from '@/api';
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            }
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
                dateVal: '',
                dateVal2: '',
            }
        },
        mounted() {
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出代付流水",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await WithdrawApi.transferOrder.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                let [startCreateTime, endCreateTime] = this.createDateRange;
                this.queryParams.startCreateTime = startCreateTime && (startCreateTime + ' 00:00:00');
                this.queryParams.endCreateTime = endCreateTime && (endCreateTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
            dateChange(val){
                if(val){
                    this.queryParams.startPayTime = val[0]
                    this.queryParams.endPayTime = val[1]
                }else{
                    this.queryParams.startPayTime = ""
                    this.queryParams.endPayTime = ""
                }
            }
        }
    }
</script>

<style scoped>

</style>
